<template>
  <RouterView></RouterView>
</template>

<script setup lang="ts">
  import { onMounted } from 'vue';
  import { RouterView } from 'vue-router';
  import { useQRCodeStore } from './stores/qrcode';
  import { useSocketStore } from './stores/socket';

  const qrCodeStore = useQRCodeStore();
  const socketStore = useSocketStore();

  onMounted(async () => {
    await qrCodeStore.initQrCode();
    await socketStore.initialize();

    console.log('App mounted');
  });
</script>
