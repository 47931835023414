import { createRouter, createWebHistory } from 'vue-router';
import MainRoutes from '@/router/MainRoutes';
import GameRoutes from '@/router/GameRoutes';
import AuthRoutes from '@/router/AuthRoutes';
import { useAuthStore } from '@/stores/auth';
import { useCustomizerStore } from '@/stores/customizer';

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:pathMatch(.*)*',
      component: () => import('@/views/pages/maintenance/error/Error404Page.vue')
    },
    MainRoutes,
    GameRoutes,
    AuthRoutes
  ]
});

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/auth/login'];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();
  const customizer = useCustomizerStore();

  customizer.SET_ROUTE_LOADING(true);

  if (!from.name && !auth.isAuthenticated) {
    try {
      await auth.getAuth();
    } catch (error) {
      console.log('Unable to get user info', error);
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth) && !to.fullPath.includes('.auth')) {
    if (authRequired && !auth.isAuthenticated) {
      auth.returnUrl = to.fullPath;
      return next('/auth/login');
    } else next();
  } else {
    next();
  }
});

router.afterEach(() => {
  const customizer = useCustomizerStore();
  customizer.SET_ROUTE_LOADING(false);
});
