// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia';
import { api } from '@/http/api';
import { router } from '@/router';

export const useGameStore = defineStore('game', {
  state: () => ({
    themes: [] as Array<object>,
    themesLoading: false,
    levels: [] as Array<object>,
    levelsLoading: false,
    currentMatchSet: null as any,
    matchSetLoading: false,
    nextMatchSet: null as any,
    themeId: null as unknown as number,
    levelId: null as unknown as number,
    selectedClientUUID: null as any
  }),
  getters: {
    images: (state) => {
      let returnArr: { image: { description: string; path: string } }[] = [];

      if (state.currentMatchSet) {
        returnArr.push({
          image: {
            description: state.currentMatchSet.matchSet.resource1.description,
            path: state.currentMatchSet.matchSet.resource1.path
          }
        });
        returnArr.push({
          image: {
            description: state.currentMatchSet.matchSet.resource2.description,
            path: state.currentMatchSet.matchSet.resource2.path
          }
        });
        returnArr.push({
          image: {
            description: state.currentMatchSet.matchSet.resource3.description,
            path: state.currentMatchSet.matchSet.resource3.path
          }
        });
        returnArr = returnArr.sort(() => Math.random() - 0.5);
      }

      return returnArr;
    }
  },
  actions: {
    async getThemes() {
      try {
        this.themesLoading = true;
        const response = await api.get('/api/themes');
        this.themes = response?.data;
      } catch (error) {
        console.error(error);
      }

      this.themesLoading = false;
      return this.themes;
    },

    async getLevels() {
      try {
        this.levelsLoading = true;
        const response = await api.get('/api/levels');
        this.levels = response?.data;
      } catch (error) {
        console.error(error);
      }

      this.levelsLoading = false;
      return this.levels;
    },

    async setThemeLevel(themeId: number, levelId: number, moduleId: number) {
      try {
        this.themeId = themeId;
        this.levelId = levelId;
      } catch (error) {
        console.error(error);
      }
    },

    async getMatchSet(matchSetId: number) {
      try {
        this.matchSetLoading = true;
        const response = await api.get(`/api/matchsets/${matchSetId}`);
        this.nextMatchSet = response?.data;
      } catch (error) {
        console.error(error);
      }

      this.matchSetLoading = false;
      return this.currentMatchSet;
    },

    async getRandomMatchSet(themeId: number, levelId: number) {
      try {
        this.matchSetLoading = true;
        const response = await api.get(`/api/matchsets/random/${themeId}/${levelId}`);
        this.nextMatchSet = response?.data;
      } catch (error) {
        console.error(error);
      }

      this.matchSetLoading = false;
      /*return this.matchSet;*/
    },

    setMatchSetFromNext() {
      this.currentMatchSet = this.nextMatchSet;
    },

    async remoteStart(clientUUID: string, player: any) {
      console.log('remote starting game for client:', clientUUID, ' and player:', player);
      return await api.post('/api/games', { clientUUID, player });
    },

    async remoteStop(clientUUID: string, player: any) {
      console.log('remote stopping game for client:', clientUUID, ' and player:', player);
      return await api.post('/api/games/stop', { clientUUID, player });
    },

    async startGame(player: any) {
      console.log('starting game for player:', player);

      router.push({
        path: '/game/theme/' + player.currentThemeID + '/level/' + player.currentLevelID
      });
    },

    async stopGame(player: any) {
      console.log('stopping game for player:', player);

      router.push({ path: '/game/main' });
    }
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGameStore, import.meta.hot));
}
