// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia';
import { api } from '@/http/api';
import { router } from '@/router';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useQRCodeStore } from './qrcode';
import { useGameStore } from './game';

export const useSocketStore = defineStore('socket', {
  state: () => ({
    qrCodeStore: useQRCodeStore(),
    gameStore: useGameStore(),
    connection: null as HubConnection | null,
    clientConnections: [] as Array<any>
  }),
  getters: {
    connectionId: (state) => state.connection?.connectionId,
    isConnected: (state) => state.connection?.state === 'Connected',
    connectionState: (state) => state.connection?.state
  },
  actions: {
    async initialize() {
      // TODO: Find a better way to handle this client query parameter. It would be better to pass it directly at the connection level.
      const response = await api.get(`/api/signalr/negotiate?client=${this.qrCodeStore.clientUuid}`);
      const { url, accessToken } = response.data;

      this.connection = new HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: () => accessToken
        })
        .configureLogging('debug')
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: (retryContext) => {
            return 15000;
          }
        })
        .build();

      this.connection.on('StartGame', (player) => {
        console.log('Received message: StartGame' + player.name);
        this.gameStore.startGame(player);
      });

      this.connection.on('StopGame', (player) => {
        console.log('Received message: StopGame' + player.name);
        this.gameStore.stopGame(player);
      });

      this.connection.onreconnected(() => {
        console.log('SignalR reconnected');
      });

      this.connection.onclose(() => {
        console.log('SignalR connection closed');
      });

      this.connection
        .start()
        .then(() => {
          console.log(
            'SignalR connection state:',
            this.connection?.state,
            ' - SignalR connection ID:',
            this.connectionId
          );
        })
        .catch((err) => {
          console.error(err.toString());
        });
    },

    async getClientConnections() {
      console.log('getting client connections');
      return await api.get('/api/signalr/connections').then((response) => {
        // process the response and store the connections
        this.clientConnections = response.data;
      });
    }
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGameStore, import.meta.hot));
}
