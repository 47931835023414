import { defineStore, acceptHMRUpdate } from 'pinia';
import config from '@/types/config';

export const useCustomizerStore = defineStore({
  id: 'customizer',
  state: () => ({
    route_loading: config.route_loading,
    Sidebar_drawer: config.Sidebar_drawer,
    Customizer_drawer: config.Customizer_drawer,
    mini_sidebar: config.mini_sidebar,
    fontTheme: config.fontTheme,
    inputBg: config.inputBg
  }),

  getters: {},
  actions: {
    SET_ROUTE_LOADING(payload: boolean) {
      this.route_loading = payload;
    },
    SET_SIDEBAR_DRAWER() {
      this.Sidebar_drawer = !this.Sidebar_drawer;
    },
    SET_MINI_SIDEBAR(payload: boolean) {
      this.mini_sidebar = payload;
    },
    SET_CUSTOMIZER_DRAWER(payload: boolean) {
      this.Customizer_drawer = payload;
    }
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCustomizerStore, import.meta.hot));
}
