import { type DirectiveBinding, type ObjectDirective } from 'vue';

type GenericEventHandler = () => void;
interface ExtendedDirective extends ObjectDirective {
  handleAddClass?: GenericEventHandler;
  handleRemoveClass?: GenericEventHandler;
  handleAction?: GenericEventHandler;
}

const bindEvents = (el: HTMLElement, binding: DirectiveBinding, thisDirective: ExtendedDirective) => {
  thisDirective.handleAddClass = () => {
    document.querySelectorAll('.onTouchstartMousedown').forEach((element) => {
      element.classList.remove('active');
    });

    el.classList.add('active');
  };

  thisDirective.handleRemoveClass = () => {
    el.classList.remove('active');
  };

  thisDirective.handleAction = () => {
    if (typeof binding.value === 'function') {
      binding.value();
    }

    if (typeof thisDirective.handleRemoveClass === 'function') {
      thisDirective.handleRemoveClass();
    }
  };

  el.addEventListener('mousedown', thisDirective.handleAddClass);
  el.addEventListener('touchstart', thisDirective.handleAddClass);

  // Listen for mouseup and touchend on the document to ensure it triggers even if the event ends outside the element
  document.addEventListener('mouseup', thisDirective.handleRemoveClass);
  document.addEventListener('touchend', thisDirective.handleRemoveClass);
  document.addEventListener('dragend', thisDirective.handleRemoveClass);

  el.addEventListener('touchEnd', thisDirective.handleAction);
  el.addEventListener('mouseup', thisDirective.handleAction);
};

const unBindEvents = (el: HTMLElement, thisDirective: ExtendedDirective) => {
  if (typeof thisDirective.handleAddClass === 'function') {
    el.removeEventListener('mousedown', thisDirective.handleAddClass);
    el.removeEventListener('touchstart', thisDirective.handleAddClass);
  }

  if (typeof thisDirective.handleRemoveClass === 'function') {
    document.removeEventListener('mouseup', thisDirective.handleRemoveClass);
    document.removeEventListener('touchend', thisDirective.handleRemoveClass);
    document.removeEventListener('dragend', thisDirective.handleRemoveClass);
  }

  if (typeof thisDirective.handleAction === 'function') {
    el.removeEventListener('touchEnd', thisDirective.handleAction);
    el.removeEventListener('mouseup', thisDirective.handleAction);
  }

  thisDirective.handleAction = undefined;
  thisDirective.handleAddClass = undefined;
  thisDirective.handleRemoveClass = undefined;
};

const vOnTouchstartMousedown: ExtendedDirective = {
  handleAction: undefined,
  handleAddClass: undefined,
  handleRemoveClass: undefined,
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const thisDirective = binding.dir as ExtendedDirective;
    bindEvents(el, binding, thisDirective);
    el.classList.add('onTouchstartMousedown');
  },
  // beforeUpdate(el: HTMLElement, binding: DirectiveBinding) {
  //   const thisDirective = binding.dir as ExtendedDirective;
  //   unBindEvents(el, thisDirective);
  // },
  // updated(el: HTMLElement, binding: DirectiveBinding) {
  //   console.log('updated', el);
  //   const thisDirective = binding.dir as ExtendedDirective;
  //   bindEvents(el, binding, thisDirective);
  // },
  beforeUnmount(el: HTMLElement, binding: DirectiveBinding) {
    const thisDirective = binding.dir as ExtendedDirective;
    unBindEvents(el, thisDirective);
  }
};

export default vOnTouchstartMousedown;
