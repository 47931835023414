import { type Directive } from 'vue';

const ev = (event: MouseEvent) => {
  console.log('context menu prevented');
  event.preventDefault();
};

const vDisableContextMenu: Directive = {
  mounted(el: HTMLElement) {
    el.addEventListener('contextmenu', ev);
  },
  beforeUnmount(el: HTMLElement) {
    el.removeEventListener('contextmenu', ev);
  }
};

export default vDisableContextMenu;
