import type { ThemeTypes } from '@/types/themeTypes/ThemeType';

const M4lTheme: ThemeTypes = {
  name: 'M4lTheme',
  dark: false,
  variables: {
    'border-color': '#4a5458',
    'carousel-control-size': 10
  },
  colors: {
    primary: '#ee3380',
    secondary: '#4076bb',
    info: '#03c9d7',
    success: '#00c853',
    accent: '#FFAB91',
    warning: '#ffc107',
    error: '#f16522',
    lightsecondary: '#6f7f84',
    lightsuccess: '#b9f6ca',
    lighterror: '#f9d8d8',
    lightwarning: '#fff8e1',
    darkText: '#212121',
    lightText: '#616161',
    darkprimary: '#ee3380',
    darksecondary: '#5b94d3',
    borderLight: '#d0d0d0',
    inputBorder: '#787878',
    containerBg: '#eef2f6',
    surface: '#fff',
    'on-surface-variant': '#fff',
    facebook: '#4267b2',
    twitter: '#1da1f2',
    linkedin: '#0e76a8',
    gray100: '#fafafa',
    secondary200: '#6f7f84'
  }
};

export { M4lTheme };
