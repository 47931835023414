// @ts-check
import { api } from '@/http/api';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'vue3-toastify';

export const useQRCodeStore = defineStore('qrcode', {
  state: () => ({
    clientUuid: null as string | null,
    qrCode: null as string | null,
    scannedQrCode: null as string | null,
    showScanConfirmation: false as boolean,
    paused: false as boolean
  }),
  getters: {},
  actions: {
    async initQrCode(forceNewUuid: boolean = false) {
      let uuid: string;
      if (forceNewUuid) {
        uuid = uuidv4();
        localStorage.setItem('client-uuid', uuid);
      } else {
        const storedUuid = localStorage.getItem('client-uuid');
        uuid = storedUuid ? storedUuid : uuidv4();
        localStorage.setItem('client-uuid', uuid);
      }

      this.clientUuid = uuid;
      console.log('clientUuid:', this.clientUuid);

      const response = await api.post('/api/clients/QrCode', { clientUUID: this.clientUuid });
      if (response.data) {
        this.qrCode = response.data;
        console.log('get qrCode:', this.qrCode);
      }
    },

    async onDetect(detectedCodes: any) {
      const scannedArray = detectedCodes.map((c: any) => c.rawValue);
      this.scannedQrCode = scannedArray.pop();
      console.log(this.scannedQrCode);

      this.paused = true;
    },

    onCameraOn() {
      this.showScanConfirmation = false;
    },

    onCameraOff() {
      this.showScanConfirmation = true;
    },

    onCameraError(error: any) {
      if (error.message) {
        toast.error(error.message, { autoClose: 3000, position: toast.POSITION.BOTTOM_CENTER });
      }
      // if (error.name === 'DropImageFetchError') {
      //   // drag-and-dropped URL (probably just an <img> element) from different
      //   // domain without CORS header caused same-origin-policy violation
      // } else if (error.name === 'DropImageDecodeError') {
      //   // drag-and-dropped file is not of type image and can't be decoded
      // } else {
      //   // idk, open an issue ¯\_(ツ)_/¯
      // }
    },

    trackFunctionSelected(detectedCodes: any, ctx: any) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = 'red';

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    }
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useQRCodeStore, import.meta.hot));
}
