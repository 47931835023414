import { createVuetify } from 'vuetify';
import '@mdi/font/css/materialdesignicons.css';
import { M4lTheme } from '@/theme/LightTheme';
import { en, nl } from 'vuetify/locale';

export default createVuetify({
  theme: {
    defaultTheme: 'M4lTheme',
    themes: {
      M4lTheme
    }
  },
  defaults: {
    VBtn: {},
    VCard: {
      rounded: 'md'
    },
    VTextField: {
      rounded: 'lg'
    },
    VTooltip: {
      // set v-tooltip default location to top
      location: 'top'
    }
  },
  locale: {
    locale: 'nl',
    fallback: 'en',
    messages: { nl, en }
  }
});
