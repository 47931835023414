import { defineStore, acceptHMRUpdate } from 'pinia';
import { api } from '@/http/api';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    authResponse: null as unknown,
    returnUrl: null as string | null
  }),
  getters: {
    isAuthenticated: (state) => !!state.authResponse,
    userDetails: (state) => (state.authResponse as any)?.userDetails,
    userId: (state) => (state.authResponse as any)?.userId,
    userRoles: (state) => (state.authResponse as any)?.userRoles
  },
  actions: {
    async getAuth() {
      return await api.get('/.auth/me').then((response) => {
        // const header = req.headers["x-ms-client-principal"];
        // const encoded = Buffer.from(header, "base64");
        // const decoded = encoded.toString("ascii");

        // context.res = {
        //   body: {
        //     userInfo: JSON.parse(decoded)
        //   }
        // };
        this.authResponse = response?.data.clientPrincipal ?? null;

        console.log('authResponse', typeof response?.data.clientPrincipal, response?.data.clientPrincipal);
      });
    },

    login(redirectPath = '/') {
      this.authResponse = null;
      window.location.href = `/.auth/login/aad?post_login_redirect_uri=${redirectPath}`;
    },
    /*
    async login(username: string, password: string) {
      const user = await fetchWrapper.post(`${baseUrl}/authenticate`, { username, password });

      // update pinia state
      this.user = user;
      // store user details and jwt in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user));
      // redirect to previous url or default to home page
      router.push(this.returnUrl || '/dashboard/default');
    },*/
    logout() {
      this.authResponse = null;
      localStorage.removeItem('authResponse');
      window.location.href = `/.auth/logout?post_logout_redirect_uri=/auth/login`;
    }
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
