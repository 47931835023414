export type ConfigProps = {
  route_loading: boolean;
  Sidebar_drawer: boolean;
  Customizer_drawer: boolean;
  mini_sidebar: boolean;
  fontTheme: string;
  inputBg: boolean;
};

const config: ConfigProps = {
  route_loading: false,
  Sidebar_drawer: true,
  Customizer_drawer: false,
  mini_sidebar: false,
  fontTheme: 'Inter',
  inputBg: false
};

export default config;
