import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from '@/App.vue';
import { router } from '@/router';
import vuetify from '@/plugins/vuetify';
import '@/scss/style.scss';
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import VueApexCharts from 'vue3-apexcharts';
import VueTablerIcons from 'vue-tabler-icons';
import vDisableContextMenu from '@/directives/disableContextMenu';
import vOnTouchstartMousedown from '@/directives/onTouchstartMousedown';

const app = createApp(App);

app.directive('disable-context-menu', vDisableContextMenu);
app.directive('on-touchstart-mousedown', vOnTouchstartMousedown);

app.use(router);
app.use(PerfectScrollbarPlugin);
app.use(createPinia());
app.use(VueTablerIcons);
app.use(VueApexCharts);
app.use(vuetify);

app.mount('#app');
