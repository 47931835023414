const GameRoutes = {
  path: '/game/',
  meta: {},
  redirect: '/game/theme',
  component: () => import('@/layouts/game/GameLayout.vue'),
  children: [
    {
      name: 'Main',
      path: '/game/main',
      props: true,
      component: () => import('@/views/games/Main.vue')
    },
    {
      name: 'Game keuze',
      path: '/game/theme/:themeId?/level/:playerLevel?',
      props: true,
      component: () => import('@/views/games/Game.vue')
    }
  ]
};

export default GameRoutes;
